import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import {
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import { DndContext, DragOverlay } from '@dnd-kit/core';
import SeedingMatchDisplay from './SeedingMatchDisplay';
import ParticipantDisplay from './utils/ParticipantDisplay';
import TournamentSeedingSocketHandler from './TournamentSeedingSocketHandler';

const INITIAL_PARTICIPANT = {
  name: '',
  cardPic: '',
};

const X_OFFSET = 40;
const Y_OFFSET = 80;
const UPPER_BRACKET_Y_OFFSET = 0;

const X_SPACE_BETWEEN_MATCHES = 80;
const Y_SPACE_BETWEEN_MATCHES = 145;
const MATCH_WIDTH = 250;
const MATCH_HEIGHT = 125;

export function calculateMatchPositionX(roundIndex, xOffset = 40) {
  return roundIndex * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + xOffset;
}

export function calculateMatchPositionY(
  match,
  parentMap,
  matchPositions,
  matchIndex,
  bracketYOffset,
) {
  const parents = parentMap[match.publicId];
  if (parents && parents.length > 0) {
    const parentPositions = parents.map(
      (parentMatch) => (matchPositions[parentMatch.publicId]
        ? matchPositions[parentMatch.publicId].positionY
        : 0),
    );

    const positionY = parentPositions.reduce((sum, posY) => sum + posY, 0)
      / parentPositions.length;

    return positionY;
  }

  const positionY = matchIndex * (MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES)
    + bracketYOffset
    + Y_OFFSET;

  return positionY;
}

function TournamentSeedingEditor() {
  const { publicId } = useTournament();

  const [draggedParticipant, setDraggedParticipant] = useState(INITIAL_PARTICIPANT);

  const [bracket, setBracket] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const loadBracket = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const { bracket: loadedBracket } = await post(`/tournaments/bracket/${publicId}`);

      let _bracket = loadedBracket;

      if (_bracket.length === 0) {
        setBracket(_bracket);
        setDisabled(false);
        return;
      }

      if (_bracket.length > 1) {
        const { matches } = _bracket[1];

        const hasParticipants = matches.some((match) => match.participants.length > 0);

        if (hasParticipants) {
          _bracket = _bracket.slice(0, 2);
        } else {
          _bracket = _bracket.slice(0, 1);
        }
      } else {
        _bracket = _bracket.slice(0, 1);
      }

      setBracket(_bracket);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('general:data_loading_error'));
      setDisabled(false);
    }
  };

  const startTournament = async () => {
    try {
      setDisabled(true);

      await post(`/tournaments/phase/${publicId}`, { phase: 'in_progress' });

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const saveSwap = async (participants) => {
    try {
      setDisabled(true);

      await post(`/tournaments/bracket/swap/${publicId}`, { participants });

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadBracket();
  }, []);

  const swapParticipants = (participants) => {
    const [participant1, participant2] = participants;

    const newBracket = [...bracket];
    let match1; let
      match2;

    // Find the matches containing the participants
    for (let i = 0; i < newBracket.length; i++) {
      const round = newBracket[i];
      for (let j = 0; j < round.matches.length; j++) {
        const match = round.matches[j];
        if (match.participants.some((participant) => participant.publicId === participant1)) {
          match1 = match;
        }
        if (match.participants.some((participant) => participant.publicId === participant2)) {
          match2 = match;
        }
      }
    }

    // Swap the participants in the matches
    if (match1 && match2) {
      const participantIndex1 = match1.participants.findIndex((participant) => participant.publicId === participant1);
      const participantIndex2 = match2.participants.findIndex((participant) => participant.publicId === participant2);

      if (participantIndex1 !== -1 && participantIndex2 !== -1) {
        const temp = match1.participants[participantIndex1];
        match1.participants[participantIndex1] = match2.participants[participantIndex2];
        match2.participants[participantIndex2] = temp;
      }
    }

    setBracket(newBracket);
  };

  const onDragEnd = async (event) => {
    const { over, active } = event;
    if (!over || !active) {
      setDraggedParticipant(INITIAL_PARTICIPANT);
      return;
    }

    /* const [roundIndex, matchIndex, participantIndex] = over.id.split('_');
    const [draggedRoundIndex, draggedMatchIndex, draggedParticipantIndex] = active.id.split('_'); */

    /* const newBracket = [...bracket]; */

    const toReplace = over.data.current.participant;
    const dragged = active.data.current.participant;

    /* newBracket[roundIndex].matches[matchIndex].participants[participantIndex] = dragged;
    newBracket[draggedRoundIndex].matches[draggedMatchIndex].participants[draggedParticipantIndex] = toReplace;

    setBracket(newBracket); */

    const draggedParticipantId = dragged.publicId;
    const toReplaceParticipantId = toReplace.publicId;

    if (draggedParticipantId === toReplaceParticipantId) return;

    swapParticipants([draggedParticipantId, toReplaceParticipantId]);

    await saveSwap([draggedParticipantId, toReplaceParticipantId]);
  };
  const onDragStart = (e) => {
    if (!e.active || !e.active.data || !e.active.data.current) return;

    const { participant } = e.active.data.current;

    setDraggedParticipant(participant);
  };

  const renderedBracket = useMemo(() => {
    // Step 1: Build matchMap and parentMap
    const matchMap = {};
    const parentMap = {};

    const allRounds = [...bracket];

    allRounds.forEach((round) => {
      const { matches } = round;
      matches.forEach((match) => {
        matchMap[match.publicId] = match;

        if (match.childMatch) {
          if (!parentMap[match.childMatch]) {
            parentMap[match.childMatch] = [];
          }
          parentMap[match.childMatch].push(match);
        }
      });
    });

    // Step 2: Assign positionY to matches
    const matchPositions = {};

    // Step 3: Render matches using matchPositions
    const renderedMatches = [];
    const renderedLines = [];

    bracket.forEach((round, roundIndex) => {
      const { matches } = round;
      matches.forEach((match, matchIndex) => {
        const cumulativeMatches = bracket.slice(0, roundIndex).reduce((acc, curr) => acc + curr.matches.length, 0);

        const positionX = roundIndex * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + X_OFFSET;
        const positionY = calculateMatchPositionY(
          match,
          parentMap,
          matchPositions,
          matchIndex + cumulativeMatches,
          UPPER_BRACKET_Y_OFFSET,
        );

        matchPositions[match.publicId] = { positionX, positionY };

        // Render stage name
        if (matchIndex === 0) {
          const roundNumber = match.stage.includes('round') ? ` ${matches[0].round}` : '';
          renderedMatches.push(
            <div
              style={{
                position: 'absolute',
                width: MATCH_WIDTH,
                left: positionX,
                textAlign: 'center',
              }}
              key={`stage_name_upper_${roundIndex}`}
            >
              <p className="has-text-weight-bold">
                {t(`general:${match.stage}`) + roundNumber}
              </p>
              <p>{`${t('general:best_of')} ${match.bestOf}`}</p>
            </div>,
          );
        }

        // Calculate matchNumber if needed
        const matchNumber = bracket
          .slice(0, roundIndex)
          .reduce((acc, curr) => acc + curr.matches.length, 0)
          + matchIndex
          + 1;

        renderedMatches.push(
          <SeedingMatchDisplay
            match={match}
            matchNumber={matchNumber}
            key={match.publicId}
            roundId={roundIndex}
            positionX={positionX}
            positionY={positionY}
          />,
        );
      });
    });

    // Step 4: Render lines
    allRounds.forEach((round) => {
      const { matches } = round;
      matches.forEach((match) => {
        const { positionX, positionY } = matchPositions[match.publicId];

        if (match.childMatch) {
          const childMatch = matchMap[match.childMatch];
          if (childMatch) {
            const { positionX: childPositionX, positionY: childPositionY } = matchPositions[childMatch.publicId];

            // Calculate start and end points
            const startX = positionX + MATCH_WIDTH; // Right edge of parent match
            const startY = positionY + MATCH_HEIGHT / 2 + 13;
            const endX = childPositionX; // Left edge of child match
            const endY = childPositionY + MATCH_HEIGHT / 2 + 13;
            const midX = startX + (endX - startX) / 2;

            // Create path data
            const pathData = `M${startX},${startY} H${midX} V${endY} H${endX}`;

            // Create a path element
            renderedLines.push(
              <path
                key={`${match.publicId}-${childMatch.publicId}`}
                d={pathData}
                stroke="white"
                strokeWidth={1}
                fill="none"
              />,
            );
          }
        }
      });
    });

    return { renderedMatches, renderedLines, matchPositions };
  }, [bracket, t]);

  const completeWidth = useMemo(() => {
    const upperBracketWidth = (bracket.length - 1) * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + MATCH_WIDTH;
    return Math.max(upperBracketWidth) + X_OFFSET * 2;
  }, [bracket]);

  const completeHeight = useMemo(() => {
    const mostMatchesUpper = bracket.reduce((acc, curr) => Math.max(acc, curr.matches.length), 0);

    const upperBracketHeight = mostMatchesUpper * (MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES);

    return upperBracketHeight;
  }, [bracket]);

  return (
    <div className="px-5 has-overflow-y-auto">
      <TournamentSeedingSocketHandler swapParticipants={swapParticipants} />
      <div
        className=""
      >
        { renderedBracket.length !== 0 && (
          <div className="mb-6">
            <p className="has-text-centered has-text-weight-bold">
              {`${t('general:automatic_seeding_done')}!`}
            </p>
            <p className="has-text-centered">
              {t('general:drag_and_drop_to_reorder')}
            </p>
          </div>
        )}

        <div
          className="is-relative has-margin-auto"
          style={{
            width: completeWidth,
            height: completeHeight,
          }}
        >
          <DndContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            autoScroll={false}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            <div
              className="is-relative"
              style={{ zIndex: 99 }}
            >
              {renderedBracket.renderedMatches}
            </div>

            <DragOverlay
              dropAnimation={!draggedParticipant.name ? {
                duration: 250,
                easing: 'ease',
              } : null}
            >
              {draggedParticipant.name ? (
                <ParticipantDisplay
                  participant={draggedParticipant}
                  showBorder={false}
                  isActive
                />
              ) : null}
            </DragOverlay>

          </DndContext>

          <svg
            className="is-absolute"
            style={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: completeWidth,
              height: completeHeight,
            }}
          >
            {renderedBracket.renderedLines}
          </svg>

        </div>
      </div>

      <div className="has-text-centered mt-6">
        <button
          type="button"
          className="button is-primary"
          onClick={startTournament}
        >
          {t('general:start_tournament')}
        </button>
      </div>

      <div className="mt-6 has-text-centered">
        <p className="has-text-centered has-text-grey mt-2">
          {status}
        </p>
      </div>
    </div>
  );
}

/*
          <svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>

*/

export default TournamentSeedingEditor;
